<template>
  <div class="route1">
    <!-- <img :src="iconPath" alt=""> -->
    <div class="content">
      <img :src="imgPath" alt="">
      <component :is="textSection"></component>
    </div>
  </div>
</template>
<script>
// const TextSection = () => import('@/views/Ppdb/LandingPage/fragments/alurPelaksanaan/dynamicComponent')
export default {
  props: ['textSection', 'imgPath', 'iconPath'],
  components: {
    // TextSection,
  },
  data() {
    return {
    }
  },
}
</script>
<style scoped>
.route1 {
  /* display: flex; */
  margin-top: 100px;
  /* padding-left: 100px;
  padding-right: 100px; */
  /* background-color: #357C2C; */
}

.route1 img {
  height: 96px;
  width: 96px;
}

.route1 h1 {
  color: #357C2C;
  font-family: Raleway;
  font-size: 42px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
  /* margin-left: 74px; */
  /* 52.5px */
}

.content {
  /* margin-left: 63px; */
  display: flex;
  /* background-color: #357C2C; */
  /* width: 1000px; */
}

.content img {
  min-width: 480px;
  min-height: 217px;
}

@media screen and (max-width: 560px) {
  .content {
    /* background-color: #357C2C; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 480px;
  }

  .content img {
    min-width: 320px;
    min-height: 200px;
  }
}

@media screen and (max-width: 1400px) {
  .content {
    /* background-color: #357C2C; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 480px;
  }

}
</style>
